export default [
  {
    label: 'Order',
    field: 'order',
  },
  {
    label: 'Question',
    field: 'question',
  },
  {
    label: 'Answer',
    field: 'answer',
  },
  {
    label: 'Published',
    field: 'status',
  },
  {
    label: 'Modified',
    field: 'updatedAt',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
