var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex  justify-content-end flex-wrap mb-2"},[_c('b-button',{staticClass:"mt-auto",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.create}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"14"}}),_c('span',[_vm._v(" Create question")])],1)],1)])],1),_c('AppTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.faq.limit,
      count: _vm.faq.count,
      page: _vm.faq.page,
    }},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
    var formattedRow = ref.formattedRow;
return [(column.field === 'question')?_c('span',{staticClass:"question"},[_vm._v(" "+_vm._s(row.question)+" ")]):(column.field === 'answer')?_c('span',{staticClass:"answer"},[_vm._v(" "+_vm._s(row.answer)+" ")]):(column.field === 'status')?_c('span',{staticClass:"flex-center-align"},[_c('b-badge',{staticClass:"text-white",attrs:{"variant":row.status.theme}},[_vm._v(" "+_vm._s(row.status.text)+" ")])],1):(column.label === 'Action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.edit(row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteQuestion(row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1)]):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }